<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Introduction</span>
    </div>
    <div style="margin-top: 10px" />
    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-flex xs12
              sm8
              md8
              lg8
              xl8
              class="font-weight-light"
              :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        <span class="subhead">A</span> triangle is a geometric shape that is formed by three straight lines connecting three non-colinear points.
        The three lines are called sides of the triangle. The three points are called the vertices.
        The angle formed by any two sides at their common vertex is called an interior angle of the triangle.
        <br>
        Triangles are one of the most commonly used shapes in design and architecture.
        Triangles and their properties have been known to and used by human civilizations in design and construction
        since ancient times. For example, the facets of Egypt's great pyramids are triangles.
        Egyptian's knowledge of triangles and their properties enabled them to
        design and build Egypt's great pyramids.
        <div style="margin-top: 20px" />
        <div class="font-weight-medium"
             :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Outline</span>
        </div>
        <div style="margin-top: 10px" />
        This chapter on triangles  and their properties consists of four lessons. Each lesson comes with a visually interactive MagicGraph that offers students a uniquely engaging and effective learning experience.
        <h5> Lesson 1 </h5>
        Types of triangles: Triangles can be classified based on sides (e.g. scalene, isosceles and equilateral triangles) or based on angles
        (e.g. acute-angled, obtuse-angled and right-angled triangles).
        <h5> Lesson 2 </h5>
        Features of a triangle: median, altitude and centroid.
        <h5> Lesson 3 </h5>
        Interior and exterior angles of a triangle. Triangle sum theorem &mdash; the theorem on the sum of three interior angles of a triangle. </li>
        <h5> Lesson 4 </h5>
        Pythagorean theorem: the theorem that relates the three sides of a right-angled triangle.
        <br><br>
      </v-flex>
      <v-flex xs12
              sm4
              md4
              lg4
              xl4
      >
        <h5 style="text-align:center">
          The facets of Egypt's great pyramids are triangles.
          Egyptian's knowledge of triangles and their properties enabled them to design and build the great Pyramids.
        </h5>
        <v-img class="imgC"
               padding="0px"
               max-height="500px"
               max-width="500px"
               src="/assets/giza.svg"
               contain
        />
      </v-flex>
    </v-layout>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Let's get started</span>
    </div>
    <div style="margin-top: 10px" />
    <div class="font-weight-light"
         :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
    >
      <span class="subhead">E</span>xplore our comprehensive library of interactive lessons on triangles and their properties.
    </div>
    <div style="margin-top: 20px" />
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../../../views/SubTopics.vue'
export default {
    name: 'Vectors',
    components: {
    SubtopicCard
    },
    data () {
      return {
        playerOptions: {
        preload:'auto',
        ratio: '16:9'
        },
      topics: [
      {
        headline: "Types of Triangles", description: "Ohm's law is a fundamental principle that relates the voltage applied across a conducting material to the electric current flowing through it.  ",
        img:"/assets/wdice-1.svg", path: "/mathematics/triangles/types"
      },
      { headline: "Features of Triangles", description: "Kirchhoff’s voltage law can be used to calculate voltage drops across different components of an electric circuit, simple or complex.",
        img: "/assets/wdice-2.svg", path: "/mathematics/triangles/features"
      },
      { headline: "Triangle Sum Theorem", description: "A resistor is an Ohmic device that is used to limit the flow of charge in a circuit",
          img: "/assets/wdice-3.svg", path: "/mathematics/triangles/sum"
      },
      {
        headline: "Pythagorean Theorem", description: "Most basic type of electric circuit in which a resistor and a capacitor are connected in series. ",
          img: "/assets/wdice-4.svg", path: "/mathematics/triangles/pythagorean"
      }
      ]
    }
  },
    created () {
      this.$store.commit('navigation/resetState');
      this.$store.commit('navigation/changeTitle', 'The Triangle and Its Properties');
      this.$store.commit('navigation/changeMenu', 'The Triangle and Its Properties');
      let newTopics = [
        {title: 'Types of Triangles', img:'/assets/wdice-1.svg', action: () => this.$router.push({path: '/mathematics/triangles/types'})},
        {title: 'Features of Triangles', img:'/assets/wdice-2.svg', action: () => this.$router.push({path: '/mathematics/triangles/features'})},
        {title: 'Triangle Sum Theorem',img:'/assets/wdice-3.svg', action: () => this.$router.push({path: '/mathematics/triangles/sum'})},
        {title: 'Pythagorean Theorem', img:'/assets/wdice-4.svg', action: () => this.$router.push({path: '/mathematics/triangles/pythagorean'})},
      ];
      this.$store.commit('navigation/replaceTopics', newTopics);
      let newshowhome = false;
      this.$store.commit('navigation/toggleshowhome', newshowhome);
      let newMath =true;
      this.$store.commit('navigation/replaceMath', newMath);
      let newLeftArrow =true;
      this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
      let newModule=true;
      this.$store.commit('navigation/replaceModule', newModule);  
       }
}
</script>
<style scoped lang="scss">
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;}

</style>
